@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@fontsource/dm-sans/latin-400';
@import '@fontsource/dm-sans/latin-400-italic';
@import '@fontsource/dm-sans/latin-500';
@import '@fontsource/dm-sans/latin-500-italic';
@import '@fontsource/dm-sans/latin-700';
@import '@fontsource/dm-sans/latin-700-italic';

@font-face {
  font-family: Gilroy;
  src: url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url('./assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Gilroy;
  src: url('./assets/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

#root {
  white-space: pre-line;
}

body {
  @apply bg-white;
  @apply font-dm-sans;
  @apply md:overflow-hidden;
}
